import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=1b9e2745&scoped=true&"
import script from "./BasicInfo.vue?vue&type=script&lang=ts&"
export * from "./BasicInfo.vue?vue&type=script&lang=ts&"
import style0 from "./BasicInfo.vue?vue&type=style&index=0&id=1b9e2745&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9e2745",
  null
  
)

export default component.exports