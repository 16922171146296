
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IOrderForm, IStage, ISubject } from '@/types/placeOrderTypes';
import { getStage, getSubject } from '@/api/placeOrder';
import { IServiceMap } from '@/business/BusinessFormData';

@Component({})
export default class BasicInfo extends Vue {
  @Prop({}) form!: IOrderForm;
  @Prop({}) formInfo!: IServiceMap;
  @Prop() user!: any;
  @Prop() name!: string;
  @Prop() repeatName?: boolean;
  @Prop() regBookName?: boolean;
  @Prop() regAliasName?: boolean;
  @Prop() disabledEdit?: boolean;
  private stages: IStage[] = [];
  private subjects: ISubject[] = [];

  get filterSubjects() {
    const supportSubjects: string[] = ['chinese', 'math', 'english', 'daode_fazhi', 'physics', 'chemistry', 'biology', 'history', 'geography', 'politics', 'wen', 'li', 'science', 'computer_science'];
    return this.subjects.filter(item => {
      // 初中生不要政治
      return supportSubjects.includes(item.subject) && !(item.subject === 'politics' && item.stage === 'junior');
    });
  }

  async mounted() {
    if (this.$store.state.stage) {
      this.stages = this.$store.state.stage;
    } else {
      this.stages = await getStage();
    }
    if (this.$store.state.subject) {
      this.subjects = this.$store.state.subject;
    } else {
      this.subjects = await getSubject();
    }
  }

  bookNameChange(value: string) {
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(value)) {
      this.regBookName = true;
      return;
    } else {
      this.regBookName = false;
    }
    if (value) {
      // const now = new Date();
      // let [y, m, d, h, mi] = [now.getFullYear().toString(), (now.getMonth() + 1).toString(), now.getDate().toString(), now.getHours().toString(), now.getMinutes().toString()];
      // if (m.length === 1) m = '0' + m;
      // if (d.length === 1) d = '0' + d;
      if (value) {
        // this.form.alias = value + '-' + y + m + d + '-' + h + mi;
        this.form.alias = value;
      }
    } else {
      this.form.alias = '';
    }
  }
  aliasChange(value: string) {
    if (!/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(value)) {
      this.regAliasName = true;
    } else {
      this.regAliasName = false;
    }
  }
};
